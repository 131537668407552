.navigation {
  background-color: rgba($black, 0.95);
  box-shadow: 0 0.125 * $line-height 0.125 * $line-height rgba($black, 0.33);
  height: 3 * $line-height;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 999;

  &__container {
    @include container(space-between);
    height: 2 * $line-height;
    padding: 0.5*$line-height $line-height;
  }

  &__logo {
    height: 2 * $line-height;

    a {
      color: $white;
      display: block;
      font-family: $font-family--display;
      font-size: 1.5 * $font-size;
      font-style: italic;
      font-weight: 500;
      line-height: 2 * $line-height;
      text-decoration: none;
      vertical-align: top;

      span {
        display: none;
      }

      abbr {
        text-transform: lowercase;
      }

      @include breakpoint(tablet) {
        span {
          display: inline;
        }

        abbr {
          display: none
        }
      }
    }
  }

  &__links {
    @include inline-flex;

    font-family: $font-family--secondary;
    padding: 0.25 * $line-height 0;
    text-transform: uppercase;

    a {
      border: solid 1px $purple;
      border-radius: 0.125 * $line-height;
      color: $white;
      font-weight: 500;
      line-height: $line-height;
      margin-left: $line-height;
      padding: 0.25 * $line-height 0.5 * $line-height;
      text-decoration: none;
      transition: all ease-in-out 0.25s;

      &:hover, &.router-link-active {
        background-color: $purple;
        color: $white;
      }
    }
  }
}