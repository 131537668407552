@mixin font-face($font-family, $file-name, $font-style, $font-weight) {
  @font-face {
    font-family: $font-family;
    src: url('../fonts/#{$file-name}.eot'); /* IE9 Compat Modes */
    src: url('../fonts/#{$file-name}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/#{$file-name}.otf') format('OpenType'), /* Modern Browsers */
    url('../fonts/#{$file-name}.woff') format('woff'), /* Modern Browsers */
    url('../fonts/#{$file-name}.svg##{$file-name}') format('svg'); /* Legacy iOS */
    font-style: $font-style;
    font-weight: $font-weight;
  }
}