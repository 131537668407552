// Anglecia Pro Display
@include font-face("Anglecia Pro Display", "AngleciaProDisplay-Regular", normal, 400);
@include font-face("Anglecia Pro Display", "AngleciaProDisplay-Italic", italic, 400);
@include font-face("Anglecia Pro Display", "AngleciaProDisplay-Medium", normal, 500);
@include font-face("Anglecia Pro Display", "AngleciaProDisplay-MediumItalic", italic, 500);
@include font-face("Anglecia Pro Display", "AngleciaProDisplay-Bold", normal, 700);
@include font-face("Anglecia Pro Display", "AngleciaProDisplay-BoldItalic", italic, 700);

// IBM Plex Mono
@include font-face("IBM Plex Mono", "IBMPlexMono", normal, 400);
@include font-face("IBM Plex Mono", "IBMPlexMono-Italic", italic, 400);
@include font-face("IBM Plex Mono", "IBMPlexMono-Medium", normal, 500);
@include font-face("IBM Plex Mono", "IBMPlexMono-MediumItalic", italic, 500);
@include font-face("IBM Plex Mono", "IBMPlexMono-Bold", normal, 700);
@include font-face("IBM Plex Mono", "IBMPlexMono-BoldItalic", italic, 700);

// IBM Plex Sans
@include font-face("IBM Plex Sans", "IBMPlexSans", normal, 400);
@include font-face("IBM Plex Sans", "IBMPlexSans-Italic", italic, 400);
@include font-face("IBM Plex Sans", "IBMPlexSans-SemiBold", normal, 600);
@include font-face("IBM Plex Sans", "IBMPlexSans-SemiBoldItalic", italic, 600);
@include font-face("IBM Plex Sans", "IBMPlexSans-Bold", normal, 700);
@include font-face("IBM Plex Sans", "IBMPlexSans-BoldItalic", italic, 700);

// IBM Plex Serif
@include font-face("IBM Plex Serif", "IBMPlexSerif-Light", normal, 200);
@include font-face("IBM Plex Serif", "IBMPlexSerif-LightItalic", italic, 200);
@include font-face("IBM Plex Serif", "IBMPlexSerif", normal, 400);
@include font-face("IBM Plex Serif", "IBMPlexSerif-Italic", italic, 400);
@include font-face("IBM Plex Serif", "IBMPlexSerif-Bold", normal, 700);
@include font-face("IBM Plex Serif", "IBMPlexSerif-BoldItalic", italic, 700);