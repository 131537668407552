.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img {
  &.alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  &.alignnone {
    margin: 5px 20px 20px 0;
  }

  &.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  &.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.wp-caption {
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;

  &.alignnone {
    margin: 5px 20px 20px 0;
  }

  &.alignleft {
    margin: 5px 20px 20px 0;
  }

  &.alignright {
    margin: 5px 0 20px 20px;
  }

  img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
  }

  p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
  }
}

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */

  &:focus {
    background-color: #EEEEEE;
    clip: auto !important;
    clip-path: none;
    color: #444444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
  }
}

// Gutenberg Styles
.wp-block-columns {
  display: flex;
}

.wp-block-column {
  flex: 1;
}