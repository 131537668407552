* {
  box-sizing: border-box;
}

html {
  background-attachment: fixed;
  background-color: rgba($cream, 0.25);
  // $background-color: $white;
  background-size: 100% 100%;
  min-height: 100%;
  width: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  position: relative;
  width: 100%;
}

main {
  padding-top: 3 * $line-height;
}

img, svg {
  display: block;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  outline: 0;
  width: auto;
}

.container {
  @include container();
}

.hidden {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}