label {
  font-family: $font-family--secondary;
  font-size: 0.75 * $font-size;
  font-weight: 600;
  line-height: $line-height;
  text-transform: uppercase;

  &:after {
    content: "";
    margin-right: 0.125 * $line-height;
  }
}