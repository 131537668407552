.cards {
  @include container();

  margin-bottom: $line-height;
  margin-top: 0;
  position: relative;

  &.products {
    margin-bottom: 0;
  }
}

.card {
  @include cols(1, 1);

  background-color: $white;
  border: solid 1px $white;
  border-radius: 0.25 * $line-height;
  box-shadow: 0 0.125 * $line-height 0.25 * $line-height rgba($black, 0.33);
  overflow: hidden;
  position: relative;
  transition: all ease-in-out 0.25s;

  @include breakpoint(tablet) {
    @include cols(1, 2);
  }

  @include breakpoint(tablet-wide) {
    @include cols(1, 3);
  }

  @include breakpoint(desktop-wide) {
    @include cols(1, 4);
  }

  &--product {
    margin-bottom: 2 * $line-height;
    position: relative;

    .card__wrapper {
      padding-bottom: 3 * $line-height;
    }
  }

  &:hover {
    background-color: #FFFFFF;
    border: solid 1px #FFFFFF;
    box-shadow: 0 0.25 * $line-height 0.5 * $line-height rgba($black, 0.33);
    transition: all ease-in-out 0.25s;

    .card__image {
      background-size: auto 125%;
      transition: background-size ease-out 0.25s;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__image {
    background-position: center center;
    background-size: auto 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
    transition: background-size ease-out 0.5s;

    &__map {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__content {
    padding: $line-height;
    position: relative;
  }

  &__info {
    margin-bottom: 4 * $line-height;
  }

  &__purchase {
    @include container();

    bottom: 0;
    left: 0;
    padding: $line-height;
    position: absolute;
    width: 100%;

    div {
      font-family: $font-family--display;
      font-size: 1.5 * $font-size;
      font-style: italic;
      font-weight: 700;
      line-height: 2 * $line-height;
    }
  }
}