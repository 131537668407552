.select {
  display: inline-block;
  vertical-align: middle;
  width: auto;

  select {
    display: block;
    font-family: $font-family--mono;
    font-size: 0.75 * $font-size;
    font-weight: 400;
    height: $line-height;
    line-height: 0.75 * $line-height;
    margin: 0;
    padding: 0.125 * $line-height 0.25 * $line-height;
    position: relative;
    width: auto;
    z-index: 1;

    &::-ms-expand {
      display: none;
    }
  }

}