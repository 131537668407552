button {
  background-color: $purple;
  border: none;
  border-radius: 0.125 * $line-height;
  color: $white;
  cursor: pointer;
  font-family: $font-family--secondary;
  font-size: $font-size;
  font-weight: 500;
  line-height: 2 * $line-height;
  padding: 0 $line-height;
  text-transform: uppercase;
  transition: background-color ease-in-out 0.25s;

  &:hover {
    background-color: $purple-alt;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}