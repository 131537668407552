#cart {

}

.cart {
  @include container();

  .cart-items {
    @include cols(1, 2);
  }

  .cart-totals {
    @include cols(1, 2);
  }
}
