.hero {
  @include container();

  //background: url("../images/hero.jpg");
  background-position: center center;
  background-size: cover;
  padding: 0;

  &__container {
    //background-color: rgba($purple-alt, 0.75);
  }

  h1 {
    color: $black;
    font-size: 2.5 * $font-size;
    line-height: 3 * $font-size;
    margin: 0 auto;
    padding: 2 * $line-height $line-height;
    text-align: center;
    //text-shadow: 0 0 0.125 * $line-height rgba($purple-alt, 0.75);
    width: 100%;
  }
}