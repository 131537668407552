.page {
  &__content {
    @include container();

    margin-bottom: 3 * $line-height;

    &:nth-of-type(even) {
      direction: rtl;

      .rendered-text {
        @include breakpoint(tablet-wide) {
          padding-left: 4 * $line-height;
          padding-right: 0;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    h1, h2, h3 {
      width: 100%;
    }

    .featured-image {
      @include cols(1, 1);

      margin-bottom: $line-height;

      @include breakpoint(tablet-wide) {
        @include cols(1, 2);

        margin-bottom: 0;
      }
    }

    .rendered-text {
      direction: ltr;
      text-align: justify;

      @include breakpoint(tablet-wide) {
        @include cols(1, 2);

        padding-right: 4 * $line-height;
      }

      p:last-of-type {
        margin-bottom: 0
      }
    }
  }
}
