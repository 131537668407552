.product-filter {
  margin: 2 * $line-height 0 $line-height 0;

  &__wrapper {
    @include container(flex-start);

    > div {
      margin-right: $line-height;
    }
  }
}
