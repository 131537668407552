/*
 * Grid Defaults
 */

// Default units
$font-size: 1rem;
$line-height: $font-size * 1.5;

// column measurements
$grid-column-width: 5rem; // 5rem = exactly 960px at desktop breakpoint
$grid-type: magic; // fluid max width = 100%; magic max width = largest breakpoint width
$grid-gutter: $line-height; // value or false
$grid-padding: false; // value or false
$container-padding: $line-height;

// responsive breakpoints
$grid-breakpoints: (
  tablet: 40rem,
  tablet-wide: 60rem,
  desktop: 80rem
);

// colors
$white: #F5F5F5;
$cream: #E6DDC3;
$grey: #333333;
$grey-alt: #444444;
$black: #171312;
$purple: #64487D;
$purple-alt: #3F2B57;

// fonts
$font-family--primary: "IBM Plex Serif", serif;
$font-family--secondary: "IBM Plex Sans", sans-serif;
$font-family--display: "Anglecia Pro Display", serif;
$font-family--mono: "IBM Plex Mono", monospace;