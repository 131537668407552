html {
  color: $black;
  font-family: $font-family--primary;
  font-size: $font-size;
  font-variant-ligatures: common-ligatures;
  line-height: $line-height;
  text-rendering: optimizeLegibility !important;
}

h1 {
  font-family: $font-family--display;
  font-size: 2.5 * $font-size;
  font-style: italic;
  font-weight: 700;
  line-height: 3 * $line-height;
  margin-bottom: 2 * $line-height;
  margin-top: 0;
}

h2 {
  font-family: $font-family--display;
  font-size: 2.5 * $font-size;
  font-style: italic;
  font-weight: 700;
  line-height: 2 * $line-height;
  margin-top: 0;
}

h3 {
  font-family: $font-family--display;
  font-size: 2 * $font-size;
  font-style: italic;
  font-weight: 700;
  line-height: 2 * $line-height;
  margin-bottom: $line-height;
  margin-top: 0;
}

p {
  margin-bottom: $line-height;
  margin-top: 0;
}

abbr {
  text-decoration: none;
}