.form {
  margin: 0;

  .input-wrapper {
    @include container();

    margin-bottom: $line-height;
    padding: 0;

    label {
      @include cols(1, 1);

      @include breakpoint(tablet) {
        @include cols(1, 4);
      }
    }

    input, select, textarea {
      @include cols(3, 4);
    }
  }
}